<template>
  <div  class="modal fade" id="preview3dModal" tabindex="-1">
    <div class="modal-dialog modal-xl">
        <div  class="modal-content text-center">
            <div v-if="showModal" class="modal-body modal-body-gallery">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
                <div>
                  <iframe v-if="threed" :src="threed.link" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="1000" height="626" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    const threed = computed(() => {
      return store.getters['configurator/threed']
    })
    const showModal = computed(() => {
      return store.getters['configurator/previewThreedModal']
    })
    function closeModal () {
      store.commit('configurator/SET_PREVIEW_THREED_MODAL_SHOW', false)
    }
    return {
      showModal,
      closeModal,
      threed
    }
  }
}
</script>
