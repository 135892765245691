<template>
  <main class="content container">
    <div class="row">
      <div class="col-lg-8">
        <InfoBox />
        <Preview />
        <div class="row">
          <div class="col-3">
            <a href="#!" @click="printed" class="link-icon mt-4"><i class="fas fa-print"></i> Angebot drucken</a>
          </div>
          <div class="col-9">
            <Carousel v-if="!loading" styleClass="carousel slide configurator__carousel" :key="componentIndex"/>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <ControlBox />
      </div>
    </div>
  </main>
  <StepNavigation/>
  <div class="bg-light">
    <div class="container">
      <Options />
      <ContentDescription :description="configuratorDescription"/>
    </div>
  </div>
<RequestForm />
<CarouselModal />
<PreviewImageModal />
<PreviewThreedModal />
<LinkModal />
</template>
<script>
import { onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import { computed, ref } from '@vue/reactivity'
import { useMeta } from 'vue-meta'
import { useStore } from 'vuex'
import { Base64 } from 'js-base64'
import axios from 'axios'
import InfoBox from '~/pages/configurator/common/infoBox'
import ControlBox from '~/pages/configurator/common/controlBox'
import Preview from '~/pages/configurator/common/preview'
import Carousel from '~/pages/configurator/common/carousel'
import StepNavigation from '~/pages/configurator/stepNavigation'
import Options from '~/pages/configurator/options'
import RequestForm from '~/pages/configurator/common/requestForm'
import CarouselModal from '~/pages/configurator/common/carouselModal'
import PreviewImageModal from '~/pages/configurator/common/previewImageModal'
import PreviewThreedModal from '~/pages/configurator/common/preview3dModal'
import LinkModal from '~/pages/configurator/common/linkModal'
import ContentDescription from '~/pages/configurator/common/contentDescription'

export default {
  components: {
    InfoBox,
    ControlBox,
    Preview,
    Carousel,
    StepNavigation,
    Options,
    RequestForm,
    CarouselModal,
    PreviewImageModal,
    PreviewThreedModal,
    LinkModal,
    ContentDescription
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const toggleSteps = ref(false)
    const componentIndex = ref(1)
    // computed
    const loading = computed(() => {
      return store.getters['configurator/loading']
    })
    const title = computed(() => {
      return store.getters['configurator/name']
    })
    const metaTitle = computed(() => {
      return store.getters['configurator/metaTitle']
    })
    const price = computed(() => {
      return store.getters['configurator/price']
    })
    const totalPrice = computed(() => {
      return Math.ceil(price.value.total)
    })
    const oldPrice = computed(() => {
      if (typeof price.value.old_price !== 'undefined') {
        if (price.value.old_price === Math.ceil(price.value.total)) {
          return 0
        } else {
          return price.value.old_price
        }
      } else {
        return 0
      }
    })
    const configuratorDescription = computed(() => {
      return store.getters['configurator/configuratorDescription']
    })

    // methods
    function hideSteps () {
      toggleSteps.value = !toggleSteps.value
    }
    async function getConfigurator () {
      const apiKey = btoa(process.env.VUE_APP_API_KEY)
      const apiUrl = process.env.VUE_APP_API_URL
      const urlParam = window.location.pathname.split('/')[2]
      const config = urlParam ?? 'schiebetore'
      const { data } = await axios.post(apiUrl + 'configurator/domain', { re: apiKey, name: config })
      if (Object.keys(data).length > 0) {
        store.dispatch('configurator/setConfigurator', data)
      } else {
        // 404
        router.push({ name: 'notFound' })
      }
    }
    async function printed () {
      const apiKey = btoa(process.env.VUE_APP_API_KEY)
      const apiUrl = process.env.VUE_APP_API_URL
      const summary = JSON.stringify(store.getters['configurator/summary'])
      const p = document.getElementById('print-content')
      const config = Base64.encode(summary)
      await axios.post(apiUrl + 'print/configurator', { re: apiKey, config: config }).then(resp => {
        if (resp.data) {
          p.innerHTML = resp.data
          setTimeout(() => {
            window.print()
          }, 2000)
        }
      })
    }
    // on mounted
    onMounted(() => {
      getConfigurator()
      componentIndex.value += 1
    })
    // set meta
    useMeta(computed(() => ({ title: metaTitle.value })))
    return {
      componentIndex,
      hideSteps,
      loading,
      toggleSteps,
      title,
      totalPrice,
      oldPrice,
      configuratorDescription,
      printed
    }
  }
}
</script>
